import ApiService from '@/services/ApiService'
import {BtoF} from '@/services/MomentService'
import Gate from '@/services/Gate/Gate'

export default {
    state: {
        dateErrKeys: [
            'due_date',
            'assign_date',
            'reminders_every',
            'reminders_period',
            'reminders_days',
            'recurrence_every',
            'recurrence_period',
            'recurrence_days',
            'recurrence_until_repeats',
            'recurrence_until_date',
        ],
        task: {},
        entity: {},
        permissions: {
            reviewAndApproveView: false,
            sendForSignatureView: false,
            signView: false,
            finishView: false,
            create: false,
            edit: false,
            view: false,
        },
        updateTicker: 0
    },
    getters: {
        taskName: (state) => state.task.name,
        taskPermissions: (state) => state.permissions,
    },
    mutations: {
        setTask: (state, data) => (state.task = data),
        setTaskEntity: (state, data) => (state.entity = data),
        setTaskPermissions: async (state) => {
            state.permissions = {
                reviewAndApproveView: await Gate.can('reviewAndApproveView', 'task', state.entity.id),
                sendForSignatureView: await Gate.can('sendForSignatureView', 'task', state.entity.id),
                signView: await Gate.can('signView', 'task', state.entity.id),
                finishView: await Gate.can('finishView', 'task', state.entity.id),
                create: await Gate.can('create', 'task'),
                edit: await Gate.can('edit', 'task', state.entity.id),
                view: await Gate.can('view', 'task', state.entity.id),
            }
        },
        updateTicker: state => state.updateTicker++
    },
    actions: {
        fetchTaskPermissions({commit}, id) {
            commit('setTaskEntity', {})
            return new Promise((resolve, reject) => {
                ApiService.api.app
                    .getModelPermissions('task', id)
                    .then((res) => {
                        commit('setTaskEntity', res.data.data)
                        commit('setTaskPermissions')
                        resolve(res.data.data)
                    })
                    .catch((err) => {
                        commit('setTaskEntity', {})
                        commit('setTaskPermissions')
                        resolve({})
                        reject(err)
                    })
            })
        },
        fetchTask({commit}, data) {
            commit('setTask', {})
            return new Promise((resolve, reject) => {
                ApiService.api.tasks
                    .getTask({params: data})
                    .then((res) => {
                        commit('setTask', res.data.data)
                        resolve(res.data.data)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        fetchTasks(vm, data) {
            return new Promise((resolve, reject) => {
                ApiService.api.tasks
                    .fetchTasks(data)
                    .then((res) => {
                        const data = res.data.data.map((i, index) => ({
                            ...i,
                            id: i.id,
                            task_id: i.task_id,
                            task_name: i.task_name,
                            project_name: i.project_name,
                            required_action: i.required_action,
                            due_date: BtoF({value: i.due_date}),
                            status: i.status.capitalize(),
                            priority: i.priority.capitalize(),
                            internal_assignee: i.internal_assignee,
                            internal_signer: i.internal_signer,
                            external_assignee: i.external_assignee,
                            external_signer: i.external_signer,
                            package: i.package,
                            reminders: i.reminders ? 'Yes' : 'No',
                        }))
                        resolve({...res.data, data: data})
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        onTickerUpdate({commit}) {
            commit('updateTicker')
        }
    },
}
