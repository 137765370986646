export const PENDING_EXTERNAL_SIGNATURE = 'Pending External Signature';
export const PENDING_INTERNAL_SIGNATURE = 'Pending Internal Signature';
export const PENDING_ACKNOWLEDGEMENT = 'Pending Acknowledgement';
export const PENDING_SUBMISSION = 'Pending Submission';
export const PENDING_APPROVAL = 'Pending Approval';
export const FULLY_EXECUTED = 'Fully Executed';
export const COMPLETED = 'Completed';
export const CANCELED = 'Cancelled';
export const REVOKED = 'Revoked';

export const OPEN_TASKS = [
    PENDING_EXTERNAL_SIGNATURE,
    PENDING_INTERNAL_SIGNATURE,
    PENDING_SUBMISSION,
    PENDING_APPROVAL,
    PENDING_ACKNOWLEDGEMENT,
];

export const CLOSED_TASKS = [
    FULLY_EXECUTED,
    COMPLETED,
    CANCELED,
    REVOKED,
];

export const DONE_TASKS = [
    FULLY_EXECUTED,
    COMPLETED,
];

export default {
    PENDING_EXTERNAL_SIGNATURE,
    PENDING_INTERNAL_SIGNATURE,
    PENDING_ACKNOWLEDGEMENT,
    PENDING_SUBMISSION,
    PENDING_APPROVAL,
    FULLY_EXECUTED,
    COMPLETED,
    CANCELED,
    REVOKED,
}
