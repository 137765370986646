export const BUYER_INITIALS = {
    key: 'buyer_initials',
    label: 'Buyer initials'
};

export const BUYER_SIGNATURE = {
    key: 'buyer_signature',
    label: 'Buyer signature'
};

export const SELLER_INITIALS = {
    key: 'seller_initials',
    label: 'Seller initials'
};

export const SELLER_SIGNATURE = {
    key: 'seller_signature',
    label: 'Seller signature'
};

export const SIGNABLE_SHORTCODES = [
    BUYER_INITIALS,
    BUYER_SIGNATURE,
    SELLER_INITIALS,
    SELLER_SIGNATURE
]
