<template>
    <CModal scrollable size="xl" class="py-4" :visible="visible" @close="$emit('closed')">
        <CModalHeader>
            <CModalTitle>Document Preview</CModalTitle>
        </CModalHeader>
        <CModalBody class="content-body">
            <div v-if="isMsWord" class="webviewer-container">
                <div class="webviewer" ref="viewer"></div>
            </div>
            <iframe v-else :src="source"></iframe>
        </CModalBody>
    </CModal>
</template>

<script>
import {baseURL, getVerUri} from '@/services/ApiService';
import {MS_WORD_EXTENSIONS} from "@/domain/Entities/Upload/extensions";
import BaseConfigs from "@/api/v2/BaseConfigs";
import WebViewer from "@pdftron/webviewer";
import config from "@/domain/config";

const DOCUMENT_TYPES = ['scopes', 'tasks', 'templates', 'standard-templates'];

export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            required: true,
            validator: (value) => DOCUMENT_TYPES.includes(value),
        },
        typeId: {
            type: Number,
            required: true,
        },
        route: {
            type: String,
            default: 'preview',
        },
        extension: {
            type: String,
            default: null
        },
        query: {
            type: Object,
            default: null
        }
    },
    computed: {
        source() {
            const random = Math.random();

            return baseURL + getVerUri(`${this.type}/${this.typeId}/${this.route}`, 2) + `?v=${random}` + this.urlQuery;
        },
        urlQuery() {
            return this.query ? this.queryToString() : '';
        },
        isMsWord() {
            return this.extension && MS_WORD_EXTENSIONS.includes(this.extension);
        }
    },
    watch: {
        visible(value) {
            if (value && this.isMsWord) {
                this.setUpWordPreview();
            }
        }
    },
    methods: {
        queryToString() {
            let query = '';

            Object.keys(this.query).forEach(key => query += `&${key}=${this.query[key]}`)

            return query
        },
        setUpWordPreview() {
            this
                .loadWordDocument()
                .then(blob => this.setUpWebviewer(blob));
        },
        loadWordDocument() {
            return new BaseConfigs()
                .asDownload(this.source)
                .then(response => response.data)
        },
        setUpWebviewer(blob) {
            this.getWebviewerInstance(blob)
                .then(instance => {
                    instance.UI.setZoomLevel('100%');
                    instance.UI.disableElements(['toggleNotesButton']);
                });
        },
        getWebviewerInstance(blob) {
            return WebViewer({
                initialDoc: URL.createObjectURL(blob),
                licenseKey: config.services.apryse.licenseKey,
                path: `${config.app.baseUrl}/webviewer`,
                extension: this.extension,
                isReadOnly: true
            }, this.$refs.viewer);
        },
    }
};
</script>

<style scoped lang="scss">
.content-body {
    height: 100vh;

    .webviewer-container {
        display: flex;
        height: 98%;

        .webviewer {
            flex: 1;
        }
    }

    iframe {
        height: 95%;
        width: 100%;
    }
}
</style>
