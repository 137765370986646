<template>
    <ConfirmDialog ref="confirmDialog"/>
    <teleport v-if="mountedComponent" to="#header-nav">
        <CBreadcrumbItem v-if="$route.name !== 'MyProfile'">
            <router-link :to="{ name: 'SettingEmployees' }">'Employees'</router-link>
        </CBreadcrumbItem>
        <CBreadcrumbItem active>
            {{ $route.name === 'MyProfile' ? 'My Profile' : form.username }}
        </CBreadcrumbItem>
    </teleport>

    <div class="profile-page">
        <div class="profile-page__section">
            <span class="profile-page__section-title"> Login Info </span>
            <div class="profile-page__form profile-page__form_login">
                <CNInput :disabled="true" :aria-disabled="true" label="Username" v-model="form.username"/>
                <company-user-change-password :user-id="$route.params.id" :user-email="user.email" class="mb-3"
                                              @updated="(value) => (user.password_modified_date = value)"/>
            </div>
        </div>

        <div class="profile-page__section profile-page__section_bordered">
            <span class="profile-page__section-title"> Contact Info </span>

            <div class="profile-page__form">
                <div class="profile-page__form-avatar">
                    <company-user-profile-avatar-uploader :user-id="user.id" :avatar-url="user.photo"
                                                          :without-upload="false"
                                                          :display-name="[form.first_name, form.last_name]"/>
                </div>
                <div class="profile-page__form-user">
                    <div>
                        <CNInput label="First Name" v-model="form.first_name" :invalid="validator.has('first_name')"
                                 @blur="handleChangeField('first_name', $event)"
                                 @input="validator.forget('first_name')"/>
                        <CFormText v-if="validator.has('first_name')"
                                   :style="`color: ${validator.has('first_name') && 'red'}`">
                            {{ validator.get('first_name') }}
                        </CFormText>
                    </div>

                    <div>
                        <CNInput label="Last Name" v-model="form.last_name" :invalid="validator.has('last_name')"
                                 @blur="handleChangeField('last_name', $event)" @input="validator.forget('last_name')"/>
                        <CFormText v-if="validator.has('last_name')"
                                   :style="`color: ${validator.has('last_name') && 'red'}`">
                            {{ validator.get('last_name') }}
                        </CFormText>
                    </div>

                    <div>
                        <CNInput label="Initials" v-model="form.initials" :invalid="validator.has('initials')"
                                 @blur="handleChangeField('initials', $event)" @input="validator.forget('initials')"/>
                        <CFormText v-if="validator.has('initials')"
                                   :style="`color: ${validator.has('initials') && 'red'}`">
                            {{ validator.get('initials') }}
                        </CFormText>
                    </div>

                    <div v-if="professions && professions.length">
                        <CNSelect label="Select Job Title" v-model="form.profession_id"
                                  :options="professions.map(p => ({ ...p, name: p.label }))"
                                  :invalid="validator.has('profession_id')"
                                  @blur="handleChangeField('profession_id', $event)"
                                  @input="validator.forget('profession_id')"/>
                        <CFormText v-if="validator.has('profession_id')"
                                   :style="`color: ${validator.has('profession_id') && 'red'}`">
                            {{ validator.get('profession_id') }}
                        </CFormText>
                    </div>

                </div>
                <div class="profile-page__form-contacts">

                    <div v-if="offices && offices.length">
                        <CNSelect label="Select Office Location" v-model="form.company_office_id"
                                  :options="offices.map(office => ({ ...office, label: office.name, value: office.id }))"
                                  :invalid="validator.has('company_office_id')"
                                  @blur="handleChangeField('company_office_id', $event)"
                                  @input="validator.forget('company_office_id')"/>
                        <CFormText v-if="validator.has('company_office_id')"
                                   :style="`color: ${validator.has('company_office_id') && 'red'}`">
                            {{ validator.get('company_office_id') }}
                        </CFormText>
                    </div>

                    <div class="profile-page__form-business">
                        <div class="w-100">
                            <CNInput label="Business Phone" :mask="CONF.MASK_FORMAT_PHONE" v-model="form.business_phone"
                                     :invalid="validator.has('business_phone')"
                                     @blur="handleChangeField('business_phone', $event)"
                                     @input="validator.forget('business_phone')"/>
                            <CFormText v-if="validator.has('business_phone')"
                                       :style="`color: ${validator.has('business_phone') && 'red'}`">
                                {{ validator.get('business_phone') }}
                            </CFormText>
                        </div>

                        <div class="profile-page__form-ext">
                            <CNInput label="Ext" max-length="10" :numbers-only="true" v-model="form.ext"
                                     :invalid="validator.has('ext')" @blur="handleChangeField('ext', $event)"
                                     @input="validator.forget('ext')"/>
                            <CFormText v-if="validator.has('ext')" :style="`color: ${validator.has('ext') && 'red'}`">
                                {{ validator.get('ext') }}
                            </CFormText>
                        </div>
                    </div>

                    <div>
                        <CNInput label="Phone Number" :mask="CONF.MASK_FORMAT_PHONE" v-model="form.cell_phone"
                                 :invalid="validator.has('cell_phone')" @blur="handleChangeField('cell_phone', $event)"
                                 @input="validator.forget('cell_phone')"/>
                        <CFormText v-if="validator.has('cell_phone')"
                                   :style="`color: ${validator.has('cell_phone') && 'red'}`">
                            {{ validator.get('cell_phone') }}
                        </CFormText>
                    </div>

                    <div>
                        <CNInput label="Fax Number" v-model="form.fax" v-mask="'(###) ###-####'"
                                 :invalid="validator.has('fax')" @blur="handleChangeField('fax', $event)"
                                 @input="validator.forget('fax')"/>
                        <CFormText v-if="validator.has('fax')" :style="`color: ${validator.has('fax') && 'red'}`">
                            {{ validator.get('fax') }}
                        </CFormText>
                    </div>

                </div>
            </div>
        </div>

        <div class="profile-page__section d-flex">
            <div class="profile-page__form-split">
                <div v-if="user && user.company" class="profile-page__form profile-page__form_column">
                    <span class="profile-page__section-title"> Permissions </span>
                    <div class="profile-page__form profile-page__form_company">
                        <CNInput :aria-disabled="true" :disabled="true" label="Company Name"
                                 v-model="user.company.name"/>
                        <router-link v-if="user && user.company"
                                     :to="{ name: 'CompanyInfo', params: { id: user.company.id } }"
                                     :title="user.company.name">
                            <CIcon name="cilExternalLink"/>
                        </router-link>
                    </div>
                    <CNInput v-if="!userCanSectionEdit('company_permissions') && authUser" :aria-disabled="true"
                             :model-value="authUser?.role?.show_name" label="Role" disabled/>
                    <CNSelect v-else label="Select Role" v-model="form.role_id" :disabled="isOwner"
                              :options="companyRoles.map(r => ({ ...r, name: normalizeRoleName(r.show_name), value: r.id }))"/>

                    <div class="profile-page__form-radio">
                        <span>Point of Contact (POC)</span>
                        <CFormCheck type="radio" :checked="isUserOfficeLead" disabled label="Yes"/>
                        <CFormCheck type="radio" :checked="!isUserOfficeLead" disabled label="No"/>
                    </div>
                    <div class="profile-page__form-radio">
                        <span>Company Employee</span>
                        <CFormCheck type="radio" :checked="true" disabled label="Yes"/>
                        <CFormCheck type="radio" :checked="false" disabled label="No"/>
                    </div>
                </div>
            </div>

            <div class="profile-page__form-split">
                <div class="profile-page__form profile-page__form_column">
                    <span class="profile-page__section-title"> Notifications </span>

                    <div class="profile-page__form-radio">
                        <span>Project Announcement</span>
                        <CFormCheck type="radio" :checked="true" disabled label="Yes"/>
                        <CFormCheck type="radio" :checked="false" disabled label="No"/>
                    </div>

                    <div class="profile-page__form-radio">
                        <span>Task Updates</span>
                        <CFormCheck type="radio" :checked="true" disabled label="Yes"/>
                        <CFormCheck type="radio" :checked="false" disabled label="No"/>
                    </div>

                    <div class="profile-page__form-radio">
                        <span>Reminders</span>
                        <CFormCheck type="radio" :checked="true" disabled label="Yes"/>
                        <CFormCheck type="radio" :checked="false" disabled label="No"/>
                    </div>
                </div>

            </div>
        </div>

        <StickyFooter>
            <CButton color="primary" variant="outline" @click="cancelEdit">
                Cancel
            </CButton>
            <CButton :disabled="profileInfoSubmitting" color="primary" @click="handleSubmit">
                <CSpinner v-if="profileInfoSubmitting" size="sm"/>
                <span v-else> Update </span>
            </CButton>
        </StickyFooter>
        <confirmation-modal ref="confirmClose"/>
    </div>
</template>

<script>
import CompanyUserChangePassword from '@/components/Modals/CompanyUserChangePassword'
import CompanyUserProfileAvatarUploader from '@/components/Forms/CompanyUsersEditForm/CompanyUserProfileAvatarUploader'
import validator from '@/utils/validator'
import rules from '@/utils/validator/rules'
import {mapState} from 'vuex'
import {mask} from 'vue-the-mask'
import * as CONF from "@/utils/constants/config";
import {parseError} from '@/utils/api'
import SectionVisibilityToggle from '@/components/Forms/CompanyUsersEditForm/SectionVisibilityToggle'
import CompanyUserProfileChangeEmail from '@/components/Forms/CompanyUsersEditForm/CompanyUserProfileChangeEmail'
import ConfirmationModal from '@/components/Modals/ConfirmationModal'
import permissions from '@/utils/constants/permissions'
import InfoIcon from '@/views/Setting/Account/CompanyDetails/components/InfoIcon'
import CNInput from "@/components/ui/CNInput/CNInput.vue";
import CNInputPassword from "@/components/ui/CNInputPassword/CNInputPassword.vue";
import CNSelect from "@/components/ui/CNSelect/CNSelect.vue";
import StickyFooter from "@/components/Forms/Partials/StickyFooter.vue";
import ConfirmDialog from "@/components/Modals/ConfirmDialog.vue";

export default {
    name: 'CompanyUsersEdit',
    components: {
        ConfirmDialog,
        StickyFooter,
        CNSelect,
        CNInputPassword,
        CNInput,
        InfoIcon,
        ConfirmationModal,
        CompanyUserProfileChangeEmail,
        SectionVisibilityToggle,
        CompanyUserProfileAvatarUploader,
        CompanyUserChangePassword,
    },
    directives: {mask},
    inject: ['toast'],
    async beforeRouteLeave() {
        await this.$nextTick(async () => {
            if (this.checkFormIsDirty()) {
                await this.$refs.confirmDialog
                    .confirm({
                        text:
                            'You have unsaved changes.\n' +
                            'Are you sure, you want to leave the page?',
                        confirmText: 'Leave',
                        cancelText: 'Cancel',
                        reverse: true,
                    })
                    .then((response) => {
                        if (response) {
                            return true
                        } else {
                            return Promise.reject(false)
                        }
                    })
            } else {
                return true
            }
        })
    },
    data: () => ({
        mountedComponent: false,
        userLoading: false,
        professions: [],
        profileInfoSubmitting: false,
        sectionVisibility: {
            loginInfo: true,
            companyPermissions: true,
            notifications: true,
            contactInfo: true,
        },
        form: {
            username: null,
            first_name: null,
            last_name: null,
            initials: null,
            company_office_id: null,
            profession_id: null,
            ext: null,
            business_phone: null,
            cell_phone: null,
            fax: null,
            is_poc: null,
            is_employee: null,
            task_update_notification: null,
            task_reminder_notification: null,
            project_announcement_notification: null,
            role_id: null,
            email: null,
        },
        validator: {},
        user: {},
        companyRoles: [],
        formSnapshot: {}
    }),
    mounted() {
        this.$nextTick(() => {
            this.mountedComponent = true
        })
    },
    watch: {
        currentUser(val) {
            if (val) this.fetchUser();
        }
    },
    created() {
        this.fetchUser()
        this.fetchOffices()
        this.fetchCompanyRoles()
        this.initValidator()
        this.fetchProfessions()
    },
    methods: {
        async fetchProfessions() {
            await this.$http.setting.fetchProfessions().then((res) => {
                this.professions = res.data.map((el) => {
                    return {value: el.id, label: el.title}
                })
            })
        },
        normalizeRoleName(roleShowName) {
            return roleShowName.endsWith('Admin')
                ? roleShowName
                : roleShowName
                    .replace('Sending ', '')
                    .replace('General ', '')
                    .replace('Receiving ', '')
        },
        makeFormDataSnapshot() {
            this.formSnapshot = this.getFormDataSnapshot()
        },
        getFormDataSnapshot() {
            return {...this.formSnapshot, ...this.normalizeForm()}
        },
        checkFormIsDirty() {
            return (
                JSON.stringify(this.getFormDataSnapshot()) !==
                JSON.stringify(this.formSnapshot)
            )
        },
        userCanSectionEdit(sectionName) {
            if (!this.authUser.can(permissions.ACCOUNT_CONFIGURATION_EDIT))
                return false
            switch (sectionName) {
                case 'company_permissions':
                    return !this.isOwner
            }
        },
        normalizeForm() {
            return {
                ...this.form,
                ...{
                    business_phone: this.form.business_phone
                        ? this.form.business_phone
                        : null,
                    cell_phone: this.form.cell_phone ? this.form.cell_phone : null,
                    fax: this.form.fax ? this.form.fax : null,
                    company_office_id: this.form.company_office_id ? this.form.company_office_id : undefined,
                },
            }
        },
        handleSubmit() {
            const validationResult = this.validator.validateAll(this.form)
            if (validationResult.hasErrors) return

            this.profileInfoSubmitting = true
            this.$http.userProfiles
                .saveProfile({
                    ...this.normalizeForm(),
                    ...{user_id: this.user.id},
                })
                .then((res) => {
                    this.toast('success', res.data.message)
                    this.makeFormDataSnapshot();
                })
                .catch((err) => {
                    err = parseError(err)
                    if (err.status === 422) {
                        this.validator.errors = err.validationMessages
                    } else {
                        this.toast('error', err.message)
                    }
                })
                .finally(() => {
                    this.profileInfoSubmitting = false
                })
        },
        async cancelEdit() {
            if (this.checkFormIsDirty()) {
                if (
                    await this.$refs.confirmClose.confirm({
                        title: '',
                        text: this.$t('You have unsaved changes. Are you sure, you want to cancel them?'),
                    })
                ) {
                    this.form = {...this.form, ...this.formSnapshot}
                    window.location.reload()
                }
            }
        },
        fetchOffices() {
            this.$store.dispatch('fetchOffices')
        },
        fetchCompanyRoles() {
            this.$http.companies.getCompanyRoles().then((res) => {
                this.companyRoles = res.data.data
            })
        },
        handleChangeField(field, event) {
            this.validator.validate(field, event)
        },
        initValidator() {
            this.validator = validator({
                username: [],
                first_name: [rules.required, rules.strMax(255)],
                last_name: [rules.required, rules.strMax(255)],
                initials: [rules.strMax(10)],
                email: [rules.required, rules.email],
                company_office_id: [
                    rules.number,
                ],
                profession_id: [rules.required],
                ext: [rules.nullable, rules.numMin(0), rules.numMax(9999999999)],
                business_phone: [
                    rules.nullable,
                    rules.digitsPhone,
                    rules.strMin(7),
                    rules.strMax(15),
                ],
                cell_phone: [
                    rules.nullable,
                    rules.digitsPhone,
                    rules.strMin(7),
                    rules.strMax(15),
                ],
                fax: [
                    rules.nullable,
                    rules.digitsPhone,
                    rules.strMin(7),
                    rules.strMax(15),
                ],
                is_poc: [rules.required],
                is_employee: [rules.required],
                role_id: [rules.required, rules.number],
                task_update_notification: [rules.required],
                task_reminder_notification: [rules.required],
                project_announcement_notification: [rules.required],
            })
        },
        fetchUser() {
            let user_id = null;

            if (this.$route.name === 'MyProfile') {
                user_id = this.currentUser.id;
            }

            if (this.$route.params.id) {
                user_id = this.$route.params.id;
            }

            if (user_id) {
                this.userLoading = true
                this.$http.userProfiles
                    .showProfile({user_id})
                    .then((res) => {
                        this.user = res.data.data
                        this.form = {
                            ...this.form,
                            ...this.$objPicker(this.user, Object.keys(this.form)),
                            ...{
                                company_office_id: this.user.company_office
                                    ? this.user.company_office.id
                                    : null,
                                project_announcement_notification: this.user.preferences
                                    ? this.user.preferences.project_announcement_notification
                                    : false,
                                task_reminder_notification: this.user.preferences
                                    ? this.user.preferences.task_reminder_notification
                                    : false,
                                task_update_notification: this.user.preferences
                                    ? this.user.preferences.task_update_notification
                                    : false,
                                role_id: this.user.role ? this.user.role.id : null,
                                email: res.data.data.email,
                            },
                        }

                        this.makeFormDataSnapshot()
                    })
                    .finally(() => {
                        this.userLoading = false
                    })
            }
        },
    },
    computed: {
        ...mapState({
            offices: (state) => state.offices.offices,
        }),
        currentUser() {
            return this.$store.getters['user']
        },
        isUserOfficeLead() {
            if (this.user && this.user.company) {
                return this.user.id === this.user.company.primary_contract_id;
            }

            return false
        },
        CONF() {
            return CONF
        },
        isOwner() {
            return this.authUser.id === parseInt(this.$route.params.id) || this.authUser.id === this.currentUser.id
        }
    },
}
</script>

<style lang="scss">
.password-modified-date {
    @media (max-width: 400px) {
        font-size: 0.85rem !important;
    }
}

.companyUsersEdit {
    label,
    :deep(label) {
        @media (max-width: 992px) {
            font-weight: 500;
        }
    }

    .card-body {
        @media (max-width: 991px) {
            padding: 1rem 0;
        }
    }

    .text-uppercase {
        @media (max-width: 992px) {
            text-transform: none !important;
        }
    }

    .card {
        border-radius: 10px;
    }

    .dropdown-divider {
        @media (max-width: 991px) {
            margin: 0rem -14px 20px -14px;
        }
        margin: 0rem -31px 20px -31px;
    }

    .section-heading {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        display: flex;
        justify-content: space-between;
    }

    .section-notification-preferences {
        .col-form-label {
            @media (min-width: 575.98px) {
                text-align: end;
            }
        }
    }

    &__header {
        width: 100%;
        display: flex;
        margin-bottom: 20px;
    }

    &__row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 5px;
        max-width: 100%;
    }
}

.profile-page {
    padding: 24px;
    background: white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 48px;

    &__upload-area {
        padding: 24px;
        border: 1px dashed #00437A;
        background: rgb(171 231 250 / 25%);
        border-radius: 16px;

        &-title {
            color: #1C262F;
            font-weight: 600;
            font-size: 18px;
            margin-bottom: 16px;
        }
    }

    &__form {
        display: flex;
        gap: 24px;

        &_column {
            flex-direction: column;

            &:nth-child(2) {
                border-top: 2px solid #DEE4E8;
                padding-top: 24px;
            }
        }

        & .cn-form-input__wrapper, .cn-secret {
            width: 100%;
        }

        &-split {
            display: flex;
            flex-direction: column;
            gap: 24px;
            width: 50%;
        }

        &-radio {
            display: flex;
            gap: 10px;
            color: #1C262F;

            & span {
                min-width: 170px;
            }
        }

        &-avatar {
            width: 150px;
        }

        &-split {
            flex-direction: column;

            &:nth-child(1) {
                padding-right: 25px;
            }

            &:nth-child(2) {
                padding-left: 25px;
            }
        }

        &-user {
            width: 355px;
            display: flex;
            flex-direction: column;
            gap: 16px;
        }

        &-contacts {
            flex: auto;
            display: flex;
            flex-direction: column;
            gap: 16px;
        }

        &_company {
            display: flex;
            align-items: center;

            & svg {
                color: #0068AD;
                cursor: pointer;
            }
        }

        &-business {
            display: flex;
            gap: 16px;
        }

        &-ext {
            width: 139px;
        }

    }

    &__section {
        max-width: 1114px;

        &_bordered {
            padding: 24px 0;
            border-top: 1px solid #DEE4E8;
            border-bottom: 1px solid #DEE4E8;
        }

        &-title {
            display: block;
            position: relative;
            font-weight: 600;
            color: #1C262F;
            font-size: 20px;
            margin-bottom: 32px;

            &::after {
                content: '';
                position: absolute;
                left: 0;
                bottom: -8px;
                height: 2px;
                width: 60px;
                background: #0081C2;
            }
        }
    }
}
</style>
